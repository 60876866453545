/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { DefaultButton, TooltipHost } from '@fluentui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { styled } from '@fluentui/utilities';

import { classNamesFunction } from '@fluentui/utilities';

const getClassNames = classNamesFunction();

const getStyles = ({ theme }) => {
  const { semanticColors } = theme;

  return {
    root: {
      paddingTop: '2px',
    },
    button: {
      marginLeft: '10px',
      background: semanticColors.filterButtonBackground,
      borderRadius: '10px',
      height: '20px',
      border: '1px solid ' + semanticColors.filterButtonBorder,
      ':hover': {
        border: '1px solid ' + semanticColors.filterButtonBorderHovered,
        background: semanticColors.filterButtonBackgroundHovered,
      },
      ':hover .filterButtonText': {
        color: semanticColors.filterButtonTextHovered,
      },
      ':hover .filterButtonClose': {
        color: semanticColors.filterButtonTextHovered,
      },
    },
    label: {
      color: semanticColors.filterButtonText,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      ':hover': {
        color: semanticColors.filterButtonTextHovered,
      },
    },
  };
};

function FilterButtonBase({ status, styles, theme, text, tooltip, ...restProps }) {
  const classNames = getClassNames(styles, { theme });

  return (
    <div className={classNames.root}>
      <TooltipHost content={tooltip}>
        <DefaultButton data-testid="filterButton" disabled={status !== 'SUCCESS'} className={classNames.button} {...restProps}>
          <span className={classNames.label + ' filterButtonText'}>{text}</span>
          <i className="unity_close filterButtonClose" style={{ opacity: '0.7', fontSize: '16px', marginLeft: '5px' }} />
        </DefaultButton>
      </TooltipHost>
    </div>
  );
}

const FilterButton = styled(FilterButtonBase, getStyles, undefined, { scope: 'FilterButton' });

FilterButton.propTypes = {
  text: PropTypes.string,
  styles: PropTypes.shape({}),
};

export { FilterButton };
