import React, { useState, useEffect } from 'react';
import { processBuildingBlock } from '../processBuildingBlock';
import '../buildingBlockStyles.css';
import * as TextMapping from '../../utils/textMapping';
import ComparisonTable from '../ComparisonTable';
import PlotComparison from '../PlotComparison';
import { Pivot, PivotItem } from '@fluentui/react';
import BBPageComponent from '../BBPageComponent';
import { producturl } from '../../utils/producturl';
import { useWindowSize } from 'react-use';

function Datasheet({
  details,
  canAccess,
  onDownloadClick,
  handleContactUsClick,
  handleNoDriveLicenseClick,
  webSocket,
  downloadPDF,
  downloadXLS,
  compareCatalog,
  bbCompareDetails,
  tags,
  texts,
  showProviderHierarchy,
  getBuildingBlockCompareDetails,
  bbPageForComponent,
  getBBPageForComponent,
  appContent,
  language,
  datasheetStatus,
  matomo,
  unitSystem,
  history,
}) {
  let body = [];

  const [collapsedVkeys, setCollapsedVkeys] = useState([]);
  const [activeTabMap, setActiveTabMap] = useState(new Map());
  const [layoutMap, setLayoutMap] = useState(new Map());
  const [compareIds, setCompareIds] = useState([]);
  const compareResize = false;
  const compareLayout = 'row';
  const compareFullScreen = false;
  // eslint-disable-next-line no-unused-vars
  const [localCompareSelected, setLocalCompareSelected] = useState([]);
  const [addedStyles, setAddedStyles] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [diagramsAvailable, setDiagramsAvailable] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    getBuildingBlockCompareDetails(compareIds);
  }, [compareIds, getBuildingBlockCompareDetails]);

  function onTabClick(id, item) {
    setActiveTabMap(new Map(activeTabMap.set(id, item)));
  }

  function getActiveTab(id) {
    return activeTabMap.get(id)?.props?.itemKey;
  }

  function addStyle(id, style) {
    if (!addedStyles.includes(id)) {
      var styleSheet = document.createElement('style');

      let styleText = '';
      if (style && style.length > 0) {
        for (let styleElement of style) {
          styleText += '.datasheet ' + styleElement + ' ';
        }
        styleSheet.innerText = styleText;
        document.head.appendChild(styleSheet);
      }

      setAddedStyles((prevStyles) => [...prevStyles, id]);
    }
  }

  function addBlocksPage(body, page) {
    body.push(
      <BBPageComponent
        page={page}
        appContent={appContent}
        bbPageForComponent={bbPageForComponent}
        language={language}
        getBBPageForComponent={getBBPageForComponent}
        texts={texts}
      />
    );
  }

  function addCompare(bodyParam, ids) {
    if (JSON.stringify(ids) !== JSON.stringify(compareIds)) {
      setCompareIds(ids);
    }

    bodyParam.push(
      <Pivot>
        <PivotItem
          itemKey="data"
          headerText={TextMapping.getUIText(TextMapping.UI_TEXT_DATA, texts)}
          data-testid="compare-data-tab-datasheet"
        >
          <div style={{ flex: 'auto', zIndex: '10' }}>
            <ComparisonTable
              data-testid="comparison-table"
              catalogs={compareCatalog}
              compareLayout={compareLayout}
              compareFullScreen={compareFullScreen}
              selectedEntities={bbCompareDetails}
              showProviderHierarchy={showProviderHierarchy}
              tags={tags}
              texts={texts}
              showPreviewThumbnails={false}
              setLocalCompareSelected={setLocalCompareSelected}
              showExport={false}
            />
          </div>
        </PivotItem>

        <PivotItem
          itemKey="chart"
          headerText={TextMapping.getUIText(TextMapping.UI_TEXT_CHART, texts)}
          data-testid="compare-chart-tab-datasheet"
        >
          <PlotComparison
            data-testid="plot-comparison"
            advancedSearchResize={compareResize}
            name="plotComparison"
            fullScreen={compareFullScreen}
            selectedEntities={bbCompareDetails}
            catalogs={compareCatalog}
            downloadPDF={downloadPDF}
            texts={texts}
            setDiagramsAvailable={setDiagramsAvailable}
          />
        </PivotItem>
      </Pivot>
    );
  }

  function handleToggler(vkey, value) {
    if (value === true) {
      setCollapsedVkeys((oldCollapsedVkeys) => [...oldCollapsedVkeys, vkey]);
    } else {
      setCollapsedVkeys((oldCollapsedVkeys) =>
        oldCollapsedVkeys.filter((collapsedVkey) => {
          return collapsedVkey !== vkey;
        })
      );
    }
  }

  function onSetLayoutMap(map) {
    setLayoutMap(map);
  }

  const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return partiallyVisible
      ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) &&
          ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
      : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
  };

  useEffect(() => {
    function getDefaultCollapsedState(child, collapsedList) {
      if (child.data && child.data.collapsed) {
        collapsedList.push(child.data.uuid);
      }

      if (child.data && child.data.content) {
        for (let element of child.data.content) {
          if (typeof element === 'object' && element != null && !Array.isArray(element) && element.data) {
            getDefaultCollapsedState(element, collapsedList);
          }
        }
      }
    }

    let collapsedList = [];

    if (details && details.data) {
      for (let element of details.data) {
        getDefaultCollapsedState(element, collapsedList);
      }

      setCollapsedVkeys(collapsedList);

      for (let buildingBlock of details.data) {
        if (buildingBlock.type === 'title') {
          let materialId = buildingBlock.data?.materialId;
          if (materialId) {
            let tile = document.getElementById('tile-' + materialId);

            if (tile) {
              if (!elementIsVisibleInViewport(tile, true)) {
                tile.scrollIntoView(false);
              }
              /*
              if (tile.scrollIntoViewIfNeeded && typeof tile.scrollIntoViewIfNeeded === 'function') tile.scrollIntoViewIfNeeded(true);
              else {
                tile.scrollIntoView(false);
              }*/
            }
          }
        }
        if (buildingBlock.type === 'tracking') {
          let tracking = buildingBlock.data;

          const urlParams = new URLSearchParams(history.location.search);

          if (tracking) {
            let newURL = encodeURI(producturl(tracking.id, history.location.pathname, tracking));

            history.push({
              pathname: newURL,
              search: urlParams.toString(),
            });
          }

          let unitSystemShortName = appContent?.unitsystems?.shortnames[unitSystem];

          if (tracking && matomo) {
            let payload = {
              name: tracking.name,
              producer: tracking.producer && tracking.producer.length > 0 ? tracking.producer.join('~') : '',
              language: language,
              unitsystem: unitSystemShortName,
              application: appContent.shortname,
              provider: tracking.provider && tracking.provider.length > 0 ? tracking.provider.join('~') : '',
              materialtype: tracking.materialtype && tracking.materialtype.length > 0 ? tracking.materialtype.join('~') : '',
            };

            matomo.datasheetView(payload);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, details.data]);

  if (details && details.data) {
    for (let buildingBlock of details.data) {
      if (buildingBlock.type !== 'title' && buildingBlock.type !== 'tracking') {
        processBuildingBlock({
          appContent,
          buildingBlock,
          body,
          windowSize,
          collapsedVkeys,
          handleToggler,
          onTabClick,
          getActiveTab,
          canAccess,
          onDownloadClick,
          layoutMap,
          setLayoutMap: onSetLayoutMap,
          handleContactUsClick: handleContactUsClick,
          handleNoDriveLicenseClick: handleNoDriveLicenseClick,
          webSocket,
          addCompare,
          downloadPDF,
          downloadXLS,
          addStyle,
          addBlocksPage,
          texts,
        });
      }
    }
  }

  return (
    <div className="datasheet datasheetBackground datasheetText" style={{ height: '100%' }}>
      <div className="amdc-block" style={{ height: '100%' }}>
        {body}
      </div>
    </div>
  );
}

export default React.memo(Datasheet);
