/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { classNamesFunction } from '@fluentui/utilities';
import React, { useState, useEffect } from 'react';
import { CommandBarButton } from '@fluentui/react';
import { NotificationBellIconProps } from './NotificationBellIcon.types';

import { useAppFrame } from '../../../context/useAppFrame';

const getClassNames = classNamesFunction();

export function NotificationBellIconBase(props) {
  const { notifications } = useAppFrame();

  const { count, onClick, hasNotifications, styles, theme, ...restProps } = props;

  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    let newNotificationCount = 0;
    for (let notification of notifications) {
      if (!notification.dismiss) {
        newNotificationCount++;
      }
    }
    setNotificationCount(newNotificationCount);
  }, [notifications]);

  const classNames = getClassNames(styles, { theme });

  return (
    <CommandBarButton {...restProps} iconProps={{ iconName: 'Ringer' }} onClick={onClick} styles={classNames.subComponentStyles.button()}>
      {notificationCount > 0 && <span className={classNames.redDot} />}
    </CommandBarButton>
  );
}

NotificationBellIconBase.propTypes = NotificationBellIconProps;
