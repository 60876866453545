import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import { processBuildingBlock } from '../processBuildingBlock';
import { Panel, PanelType } from '@fluentui/react';
import { useWindowSize } from 'react-use';

const mapStateToProps = (state) => {
  return {
    appContent: state.roots.appContent,
    config: state.roots.config,
    bbAccountPanel: state.roots.bbAccountPanel,
    ranges: state.roots.ranges,
    canAccess: state.roots.canAccess,
    language: state.roots.language,
    texts: state.roots.texts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

function AccountPanelContainer({
  appContent,
  config,
  bbAccountPanel,
  getBBAccountPanel,
  ranges,
  canAccess,
  isOpen,
  dismissPanel,
  language,
  texts,
}) {
  const [collapsedVkeys, setCollapsedVkeys] = useState([]);
  const [activeTabMap, setActiveTabMap] = useState(new Map());
  const [layoutMap, setLayoutMap] = useState(new Map());
  const [addedStyles, setAddedStyles] = useState([]);
  const [body, setBody] = useState([]);

  const windowSize = useWindowSize();

  const onTabClick = useCallback(
    (id, item) => {
      setActiveTabMap(new Map(activeTabMap.set(id, item)));
    },
    [activeTabMap]
  );

  useEffect(() => {
    if (config.accountpanel && language) {
      getBBAccountPanel(config.accountpanel.page);
    }
  }, [config, getBBAccountPanel, language]);

  const addStyle = useCallback(
    (id, style) => {
      if (!addedStyles.includes(id)) {
        var styleSheet = document.createElement('style');

        let styleText = '';
        if (style && style.length > 0) {
          for (let styleElement of style) {
            styleText += '.accountpanel' + styleElement + ' ';
          }
          styleSheet.innerText = styleText;
          document.head.appendChild(styleSheet);
        }

        setAddedStyles((prevStyles) => [...prevStyles, id]);
      }
    },
    [addedStyles]
  );

  const getActiveTab = useCallback(
    (id) => {
      return activeTabMap.get(id)?.props?.itemKey;
    },
    [activeTabMap]
  );

  function handleToggler(vkey, value) {
    if (value === true) {
      setCollapsedVkeys((oldCollapsedVkeys) => [...oldCollapsedVkeys, vkey]);
    } else {
      setCollapsedVkeys((oldCollapsedVkeys) =>
        oldCollapsedVkeys.filter((collapsedVkey) => {
          return collapsedVkey !== vkey;
        })
      );
    }
  }

  function onSetLayoutMap(map) {
    setLayoutMap(map);
  }

  useEffect(() => {
    let newBody = [];

    if (bbAccountPanel) {
      for (let buildingBlock of bbAccountPanel) {
        processBuildingBlock({
          appContent,
          buildingBlock,
          body: newBody,
          windowSize,
          collapsedVkeys,
          handleToggler,
          onTabClick,
          getActiveTab,
          canAccess,
          layoutMap,
          setLayoutMap: onSetLayoutMap,
          addStyle,
          ranges,
          texts,
        });
      }
    }

    setBody(newBody);
  }, [bbAccountPanel, layoutMap, collapsedVkeys, canAccess, ranges, addStyle, getActiveTab, onTabClick, appContent, texts, windowSize]);

  if (config.accountpanel) {
    return (
      <Panel isOpen={isOpen} type={PanelType.smallFixedFar} onDismiss={dismissPanel}>
        <div className="amdc-block accountpanel">{body}</div>
      </Panel>
    );
  } else {
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPanelContainer);
