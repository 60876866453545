import React, { useCallback, useMemo } from 'react';
import { IconButton } from '@fluentui/react';

function AdvancedSearchGroup({ child, filter, margin, matchedFilter, hasResults, collapsedVkeys, getComponentKey, handleToggler }) {
  const onClick = useCallback(() => {
    handleToggler(child._key, !collapsedVkeys.includes(child._key));
  }, [child, collapsedVkeys, handleToggler]);

  const buttonStyle = useMemo(() => {
    return { background: 'none', border: 'none', margin: 0, padding: 0, fontWeight: 'bold' };
  }, []);

  const iconButtonStyle = useMemo(() => {
    return { width: 'auto', height: 'auto' };
  }, []);

  const iconProps = useMemo(() => {
    return { iconName: collapsedVkeys.includes(child._key) ? 'RightSmall' : 'DownSmall' };
  }, [collapsedVkeys, child]);

  const divStyle = useMemo(() => {
    return {
      display: (filter !== '' && matchedFilter) || hasResults ? 'flex' : 'none',
      marginLeft: margin + 'px',
      borderBottom: '1px solid',
      marginTop: '15px',
      width: '100%',
    };
  }, [filter, matchedFilter, hasResults, margin]);

  return (
    <div className="bodyText" key={getComponentKey(child, 'group')} style={divStyle}>
      <IconButton style={iconButtonStyle} iconProps={iconProps} onClick={onClick} title="Close" />
      <button className="bodyText collapsibleGroupText" style={buttonStyle} onClick={onClick}>
        {child.name}
      </button>
    </div>
  );
}

export default AdvancedSearchGroup;
