import React, { useEffect, useState } from 'react';
import createData from '../PropertyEditor/utils/createData';
import { createDefaultSchema } from '../PropertyEditor/utils/createDefaultSchema';
import PreviewThumbnail from '../gooey/gql/PreviewThumbnail';
import { Checkbox } from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import isEmpty from 'lodash/isEmpty';

import * as actions from '../../actions';

import * as TextMapping from '../../utils/textMapping';

const mapStateToProps = (state) => {
  return {
    roots: state.roots,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

function ComparisonTable({
  catalogs,
  compareLayout,
  selectedEntities,
  contentDefinitions,
  showProviderHierarchy,
  tags,
  texts,
  showPreviewThumbnails = true,
  setLocalCompareSelected,
  exportExcel,
  roots,
  selectionLimit,
  showExport = true,
  showLimitSelectionDialog,
}) {
  let allKeys = {};

  const [uncheckedPreviews, setUncheckedPreviews] = useState([]);
  const [differencesChecked, setDifferencesChecked] = useState(false);
  const [resultCountText, setResultCountText] = useState('');
  const [manuallyChangedSelections, setManuallyChangedSelections] = useState(false);

  const compareConfig =
    catalogs && catalogs.length && catalogs[0].node && catalogs[0].node.config ? JSON.parse(catalogs[0].node.config) : null;

  useEffect(() => {
    let result = selectedEntities.filter((i) => {
      return !uncheckedPreviews.includes(i._id);
    });
    setLocalCompareSelected(result);
  }, [uncheckedPreviews, selectedEntities, setLocalCompareSelected]);

  useEffect(() => {
    if (selectedEntities.length === 0 && uncheckedPreviews.length !== 0) {
      // Clear uncheckedPreviews
      setUncheckedPreviews([]);
    }

    let newUncheckedPreviews = [];
    if (uncheckedPreviews.length) {
      newUncheckedPreviews = JSON.parse(JSON.stringify(uncheckedPreviews));
    }

    if (selectedEntities.length - uncheckedPreviews.length > selectionLimit) {
      for (let i = 0; i < selectedEntities.length; i++) {
        if (i >= selectionLimit && !uncheckedPreviews.includes(selectedEntities[i]._id)) {
          newUncheckedPreviews.push(selectedEntities[i]._id);
        }
      }

      if (newUncheckedPreviews.length !== uncheckedPreviews.length) {
        setUncheckedPreviews(newUncheckedPreviews);
      }
    }
  }, [selectedEntities, selectionLimit, uncheckedPreviews, manuallyChangedSelections]);

  useEffect(() => {
    let text = TextMapping.getUIText(TextMapping.UI_TEXT_COMPARE_RESULTS_MESSAGE, texts, {
      checked: selectedEntities.length - uncheckedPreviews.length,
      total: selectedEntities.length,
    });
    setResultCountText(text);
  }, [selectedEntities, uncheckedPreviews, texts]);

  let dataArray = [];

  for (let entity of selectedEntities) {
    let dataJson = createData({ view: 'compare', entity, schema: createDefaultSchema(entity), contentDefinitions, catalogs });
    dataJson.name = entity.name;
    dataJson.provider = entity.provider;
    dataJson.producer = entity.producer;
    dataJson.polymerfamily = entity.polymerfamily;

    if (!uncheckedPreviews.includes(entity._id)) {
      dataArray.push(dataJson);
    }
  }

  for (let m = 0; m < dataArray.length; m++) {
    for (let key in dataArray[m]) {
      if (!allKeys.hasOwnProperty(key)) {
        allKeys[key] = {};
      }
      for (let key2 in dataArray[m][key]) {
        if (dataArray[m][key][key2].type !== 'plot') {
          allKeys[key][key2] = { title: dataArray[m][key][key2].title };
        }
      }
    }
  }

  let tableRows = [];

  let headers = [];

  headers.push(<td key={'compareheaderblank'}></td>);
  for (let k = 0; k < dataArray.length; k++) {
    headers.push(
      <td
        key={'compareheader' + dataArray[k].name + k}
        className={k % 2 === 0 ? 'compare-column-alternate' : 'compare-column'}
        style={{
          borderLeft: 'thin solid',
          borderRight: 'thin solid',
          fontWeight: 'bold',
          fontSize: '14px',
        }}
      >
        {dataArray[k].name}
      </td>
    );
  }

  tableRows.push(<tr key={'compareheaderrow'}>{headers}</tr>);

  for (let key in allKeys) {
    if (key !== 'name' && key !== 'id') {
      let headerCells = [];
      headerCells.push(
        <td key={key} style={{ borderRight: 'thin solid', fontWeight: 'bold', borderTop: 'thin solid' }}>
          {key}
        </td>
      );
      for (let l = 0; l < dataArray.length; l++) {
        headerCells.push(
          <td
            className={l % 2 === 0 ? 'compare-column-alternate' : 'compare-column'}
            key={l}
            style={{
              borderRight: 'thin solid',
              borderTop: 'thin solid',
              borderLeft: 'thin solid',
            }}
          ></td>
        );
      }
      let sectionRows = [];

      for (let key2 in allKeys[key]) {
        let cells = [];
        if (allKeys[key][key2].title) {
          cells.push(
            <td key={key2} style={{ textIndent: '1em' }}>
              {allKeys[key][key2].title}
            </td>
          );

          let difference = false;

          for (let i = 0; i < dataArray.length; i++) {
            if (dataArray[i].hasOwnProperty(key) && dataArray[i][key].hasOwnProperty(key2)) {
              for (let n = 0; n < dataArray.length; n++) {
                if (
                  !dataArray[n].hasOwnProperty(key) ||
                  !dataArray[n][key].hasOwnProperty(key2) ||
                  dataArray[n][key][key2].value !== dataArray[i][key][key2].value
                ) {
                  difference = true;
                }
              }
              cells.push(
                <td
                  className={i % 2 === 0 ? 'compare-column-alternate' : 'compare-column'}
                  key={i}
                  style={{
                    borderBottom: 'none',
                    borderRight: 'thin solid',
                    borderLeft: 'thin solid',
                    padding: '5px',
                    fontWeight: difference ? 'bold' : 'normal',
                  }}
                >
                  {key2 === 'provider' && showProviderHierarchy(dataArray[i][key][key2].value, tags).join(' > ')}
                  {key2 !== 'provider' && dataArray[i][key][key2].value}
                </td>
              );
            } else {
              cells.push(
                <td
                  className={i % 2 === 0 ? 'compare-column-alternate' : 'compare-column'}
                  key={i}
                  style={{
                    borderRight: 'thin solid',
                    borderLeft: 'thin solid',
                    padding: '5px',
                  }}
                ></td>
              );
            }
          }

          if (difference || !differencesChecked) {
            sectionRows.push(<tr key={key2 + 'difference'}>{cells}</tr>);
          }
        }
      }

      if (sectionRows.length > 0) {
        tableRows.push(<tr key={key + 'header'}>{headerCells}</tr>);
        tableRows.push(sectionRows);
      }
    }
  }

  let previewThumbnails = [];

  function onDifferencesChange(e, value) {
    setDifferencesChecked(value);
  }

  function onPreviewSelected({ entity, newValue }) {
    setManuallyChangedSelections(true);
    if (newValue === false) {
      setUncheckedPreviews(uncheckedPreviews.concat(entity._id));
    } else {
      if (selectedEntities.length - uncheckedPreviews.length + 1 > selectionLimit) {
        showLimitSelectionDialog();
      } else {
        setUncheckedPreviews(uncheckedPreviews.filter((id) => id !== entity._id));
      }
    }
  }

  if (showPreviewThumbnails) {
    for (let entity of selectedEntities) {
      previewThumbnails.push(
        <div key={entity._id} style={{ marginBottom: '5px', marginRight: '5px' }}>
          <PreviewThumbnail
            onChange={onPreviewSelected}
            entity={entity}
            typeIcon={entity.type.icon}
            isChecked={!uncheckedPreviews.includes(entity._id)}
          />
        </div>
      );
    }
  }

  return (
    <div className="bodyText" style={{ padding: '10px', display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
      <div>{resultCountText}</div>
      <div
        style={{
          display: 'flex',
          flexGrow: 1,
          overflow: 'hidden',
          flexDirection: compareLayout === 'row' ? 'column' : 'row',
        }}
      >
        <div
          data-testid="comparison-table-preview-div"
          style={{
            display: !isEmpty(previewThumbnails) ? 'flex' : 'none',
            flexDirection: compareLayout,
            flex: '0 0 170px',
            overflow: 'auto',
          }}
        >
          {previewThumbnails}
        </div>
        <Checkbox
          label={TextMapping.getUIText(TextMapping.UI_TEXT_SHOW_DIFFERENCES_ONLY, texts)}
          checked={differencesChecked}
          onChange={onDifferencesChange}
          styles={{ root: { paddingTop: '5px', paddingBottom: '5px' } }}
        />
        <div data-testid="comparison-table-scrollable-div" style={{ overflow: 'auto', height: '100%' }}>
          <table key="comparetable" style={{ borderCollapse: 'collapse' }}>
            <tbody key="comparetbody">{tableRows}</tbody>
          </table>
        </div>
      </div>
      {(roots.perms.includes('ui.pdf') ||
        (roots.perms.includes('ui.compare.export') && compareConfig && compareConfig.xlsx && compareConfig.xlsx.enabled)) &&
        (selectedEntities.length - uncheckedPreviews.length > 0 ) &&
        (selectedEntities.length - uncheckedPreviews.length <= (compareConfig?.xlsx?.maxcount || compareConfig?.maxcount || 10)) &&
      showExport ? (
        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', paddingTop: '10px' }}>
          {roots.perms.includes('ui.compare.export') && compareConfig && compareConfig.xlsx && compareConfig.xlsx.enabled ? (
            <PrimaryButton
              disabled={roots.exportCompare && roots.exportCompare.isLoading}
              onClick={() => {
                exportExcel('xlsx');
              }}
              iconProps={{ iconName: 'ExcelDocument' }}
              style={{ marginRight: '10px' }}
            >
              Export
            </PrimaryButton>
          ) : null}
          {(selectedEntities.length - uncheckedPreviews.length > 0) &&
           (selectedEntities.length - uncheckedPreviews.length <= (compareConfig?.pdf?.maxcount || compareConfig?.maxcount || 10)) &&
          roots.perms.includes('ui.pdf') &&
          compareConfig &&
          compareConfig.pdf &&
          compareConfig.pdf.enabled ? (
            <PrimaryButton
              disabled={roots.exportCompare && roots.exportCompare.isLoading}
              onClick={() => {
                exportExcel('pdf');
              }}
              iconProps={{ iconName: 'PDF' }}
            >
              PDF
            </PrimaryButton>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ComparisonTable));
