/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import localPropTypes from '../../propTypes';
import { FilterInput, FilterRow } from '../styled';
import BooleanFilter from './BooleanFilter';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { connect } from 'react-redux';
import { styled } from '@fluentui/utilities';

import * as TextMapping from '../../../../../utils/textMapping';

const MAX_FILTER_ITEMS = 10;
const NO_MATCH = 0;
const EXACT_MATCH = 1;
const CHILD_MATCH = 2;

const mapStateToProps = (state) => {
  return {
    roots: state.roots,
  };
};

function BooleanFilterGroupBase(props) {
  const { group, onChange, selectedItems, toggleGroup, roots, theme, parentHeight } = props;
  const { filters } = group;
  const { texts } = roots;

  const [searchTerm, setSearchTerm] = useState('');

  const searchFilters = useCallback((event, value) => {
    setSearchTerm(value);
  }, []);

  let hasSearchTerm = searchTerm && searchTerm !== '';

  const countItems = useCallback((children) => {
    let count = children.length;
    children.map((child) => {
      if (child.children) {
        count += countItems(child.children);
      }

      return count;
    });

    return count;
  }, []);

  const mapChildren = useCallback(
    (parent, children, parentMatchesSearchTerm = 0) => {
      let limitedChildren = children;

      function matchesSearchTerm(child, depth) {
        if (hasSearchTerm && child.name && child.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          if (depth === 0) {
            return EXACT_MATCH;
          } else {
            return CHILD_MATCH;
          }
        }

        if (child.children) {
          return child.children.some((grandChild) => matchesSearchTerm(grandChild, depth + 1));
        }

        return NO_MATCH;
      }

      if (hasSearchTerm && !parentMatchesSearchTerm) {
        limitedChildren = children.filter((item) => {
          return selectedItems.includes(item.id) || matchesSearchTerm(item) > 0;
        });
      }

      let rows = limitedChildren.map((child) => {
        let searchTermMatchResult = matchesSearchTerm(child, 0);
        return (
          <div key={child.id}>
            <FilterRow>
              <FilterInput>
                <BooleanFilter
                  checked={Boolean(selectedItems.length) && selectedItems.includes(child.id)}
                  filter={child}
                  onChange={onChange}
                  toggleGroup={toggleGroup}
                  group={group}
                />
              </FilterInput>
            </FilterRow>
            {((child.children && child.children.length > 0 && hasSearchTerm && (parentMatchesSearchTerm || searchTermMatchResult > 0)) ||
              (!child.isCollapsed && child.children)) && (
              <div style={{ marginLeft: '30px' }}>
                {mapChildren(child, child.children, parentMatchesSearchTerm || searchTermMatchResult === EXACT_MATCH)}
              </div>
            )}
          </div>
        );
      });

      return <div>{rows}</div>;
    },
    [onChange, selectedItems, toggleGroup, searchTerm, group, hasSearchTerm]
  );

  const filterNodes = useMemo(() => {
    return mapChildren(group, filters);
  }, [filters, mapChildren, group]);

  const childCount = useMemo(() => {
    return countItems(filters);
  }, [countItems, filters]);

  let placeHolder = theme?.semanticColors?.filterSearchBoxPlaceholder
    ? {
        '::placeholder': {
          color: theme?.semanticColors?.filterSearchBoxPlaceholder,
        },
      }
    : null;

  let fieldColor = theme?.semanticColors?.filterSearchBoxText
    ? {
        color: theme?.semanticColors?.filterSearchBoxText,
      }
    : null;

  let iconColor = theme?.semanticColors?.filterSearchBoxIcon
    ? {
        color: theme?.semanticColors?.filterSearchBoxIcon,
      }
    : null;

  let calculatedHeight = group.config && group.config.maxItems ? group.config.maxItems * 20 + 'px' : '130px';

  if (parentHeight !== 'auto') {
    calculatedHeight = `${parentHeight - (childCount > MAX_FILTER_ITEMS ? 52 : 28)}px`;
  }

  return (
    <div>
      {(childCount > MAX_FILTER_ITEMS || hasSearchTerm) && (
        <SearchBox
          className="filterSearchBoxText filterSearchBoxBackground"
          data-testid={`filter-search-${group.name}`}
          label={'Search ' + group.name}
          styles={{
            root: {
              height: '24px',
              fontSize: '11px',
              borderRadius: '12px',
              marginBottom: '5px',
            },
            field: {
              ...placeHolder,
              ...fieldColor,
            },
            iconContainer: {
              maxWidth: '20px',
            },
            icon: {
              width: '20px',
              height: '20px',
              ...iconColor,
            },
          }}
          placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_FILTER_TAG, texts, { tagname: group.name })}
          onChange={(event, value) => {
            searchFilters(event, value);
          }}
          onClear={() => {
            searchFilters('');
          }}
          disableAnimation={true}
        />
      )}
      <div className="filterSearchBoxItems" data-testid="filter-nodes-div" style={{ overflowY: 'auto', maxHeight: calculatedHeight }}>
        {filterNodes}
      </div>
    </div>
  );
}

const BooleanFilterGroup = styled(BooleanFilterGroupBase, () => {}, undefined, { scope: 'BooleanFilterGroup' });

BooleanFilterGroup.propTypes = {
  group: localPropTypes.group,
  selectedItems: PropTypes.arrayOf(PropTypes.string.isRequired),
  onChange: PropTypes.func,
};

BooleanFilterGroup.defaultProps = {
  group: null,
  selectedItems: [],
  onChange: () => {},
};

export default connect(mapStateToProps)(React.memo(BooleanFilterGroup));
