import React, { useCallback } from 'react';
import { Checkbox } from '@fluentui/react';

function PropertySelectionRow({ child, onCheckbox1Change, onCheckbox2Change, selectionOne, selectionTwo, getKey, margin, shouldDisplay }) {
  const checkbox1Change = useCallback(
    (_ev, value) => {
      onCheckbox1Change(child, value);
    },
    [onCheckbox1Change, child]
  );

  const checkbox2Change = useCallback(
    (_ev, value) => {
      onCheckbox2Change(child, value);
    },
    [onCheckbox2Change, child]
  );

  const getCheckboxOneValue = useCallback(() => {
    if (selectionOne === getKey(child)) {
      return true;
    } else {
      return false;
    }
  }, [child, selectionOne, getKey]);

  const getCheckboxTwoValue = useCallback(() => {
    if (selectionTwo === getKey(child)) {
      return true;
    } else {
      return false;
    }
  }, [child, selectionTwo, getKey]);

  return (
    <div
      data-testid={`property-selection-row-${child?.name?.replace(' ', '-')}`}
      style={{ display: shouldDisplay ? 'flex' : 'none', marginLeft: margin }}
      className="bodyText"
    >
      <Checkbox onChange={checkbox1Change} checked={getCheckboxOneValue()} />
      <Checkbox onChange={checkbox2Change} checked={getCheckboxTwoValue()} />
      {child.name}
      {child.condition ? ', ' + child.condition : ''} {child.standard_name ? '(' + child.standard_name + ')' : ''}
    </div>
  );
}

export default PropertySelectionRow;
