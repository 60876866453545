import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';

import EntityList from '../gooey/EntityList';
import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky';
import { DetailsRow, SelectAllVisibility, ConstrainMode, TooltipHost, IconButton, Icon } from '@fluentui/react';
import { Row, Cell, CellAction, CellContent } from '../../pages/styles';
import { processBuildingBlock } from '../processBuildingBlock';
import RoundIconButton from '../../gooey/components/RoundIconButton';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { processSubstitution, hasPermission } from '../processBuildingBlock';
import * as TextMapping from '../../utils/textMapping';
import { producturl } from '../../utils/producturl';
import { isEmpty } from 'lodash';
import { useWindowSize } from 'react-use';

//const mathjs = require('mathjs');

const onRenderDetailsHeader = (props, defaultRender) => {
  const defaultHeader = defaultRender({
    ...props,
    selectAllVisibility: SelectAllVisibility.hidden,
    onRenderColumnHeaderTooltip: (tooltipHostProps) => {
      let content = '';

      if (tooltipHostProps.column && tooltipHostProps.column.tooltip) {
        content = tooltipHostProps.column.tooltip;
      }
      return <TooltipHost {...tooltipHostProps} content={content} />;
    },
  });
  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
      {defaultHeader}
    </Sticky>
  );
};

function BuildingBlockList({
  appContent,
  buildingBlocksArray,
  enableShimmer,
  actions,
  handleSelectedEntitiesChange,
  downloadPDF,
  handleContextMenu,
  onEntityInvoke,
  selectedEntities,
  onColumnHeaderClick,
  pdfDownloadWaiting,
  perms,
  preferences,
  sortColumn,
  sortDirection,
  texts,
  track,
  messageBar,
  notifyShared,
  activeId,
  updateFavoriteStatus,
  favoriteList,
  findSimilarMaterials,
  similarId,
  loading,
}) {
  const [addedStyles, setAddedStyles] = useState([]);
  const { width } = useWindowSize();
  const isMobile = width < 640;

  const [expandedGroups, setExpandedGroups] = useState({});
  const [columns, setColumns] = useState(null);
  const [items, setItems] = useState([]);

  const groupedItems = useMemo(() => {
    const groups = new Map();

    items.forEach((item) => {
      let groupKey = item.id;
      if (item.grouping) {
        groupKey = item.grouping.split('-')[0];
      }

      if (!groups.has(groupKey)) {
        groups.set(groupKey, []);
      }
      groups.get(groupKey).push({ ...item, groupKey: groupKey });
    });
    return groups;
  }, [items]);

  const flattenedItems = [];
  const groups = [];
  let startIndex = 0;

  groupedItems.forEach((value, key) => {
    const groupItems = value;
    const parentItem = {
      ...groupItems[0],
      isParent: true,
      groupName: key,
      groupCount: groupItems.length - 1,
      groupMembers: groupItems,
    };

    if (groupItems.length > 1) {
      flattenedItems.push(parentItem);
      groups.push({
        key: key,
        name: key,
        startIndex,
        count: 1,
        level: 0,
        isCollapsed: !expandedGroups[key],
      });
      startIndex += 1;

      if (expandedGroups[key]) {
        groupItems.forEach((item) => {
          if (item.id !== parentItem.id) {
            flattenedItems.push({ ...item, isChild: true, isParent: false });
            startIndex += 1;
          }
        });
      }
    } else {
      flattenedItems.push({ ...groupItems[0], isParent: false });
    }
  });

  function addStyle(id, style) {
    if (!addedStyles.includes(id)) {
      var styleSheet = document.createElement('style');

      let styleText = '';
      if (style && style.length > 0) {
        for (let styleElement of style) {
          styleText += styleElement.replace('.amdc-block', '.amdc-block .buildingblocklist') + ' ';
        }
        styleSheet.innerText = styleText;
        document.head.appendChild(styleSheet);
      }

      setAddedStyles((prevStyles) => [...prevStyles, id]);
    }
  }

  const _onRenderRow = useCallback(
    (rowProps) => {
      const { item } = rowProps;
      const cellStyles = { display: 'flex', padding: '0px' };
      let className = '';
      if (activeId === item?.id) {
        className = 'matselect';
      }

      if (similarId === item?.id) {
        className += ' similar';
      }

      if (appContent?.config?.groupmaterials && item.isChild) {
        className += ' childrow';
      }

      if (appContent?.config?.groupmaterials && item.isParent) {
        className += ' parentrow';
        let aggregatedItem = { ...item };

        /*
        for (let groupMember of item.groupMembers) {
          for (let key in groupMember) {
            if (key.startsWith('Spt')) {
              if (!aggregatedItem[key] || !Array.isArray(aggregatedItem[key])) {
                aggregatedItem[key] = [];
              }
              aggregatedItem[key].push(groupMember[key]);
            }
          }
        }

        for (let key in aggregatedItem) {
          if (key.startsWith('Spt')) {
            // check if array items are numbers
            if (aggregatedItem[key].every((item) => item !== null && !isNaN(item))) {
              aggregatedItem[key] = `${mathjs
                .format(Math.min(...aggregatedItem[key]), 3)
                .replace('e+', 'E')
                .replace('e-', 'E-')} - ${mathjs
                .format(Math.max(...aggregatedItem[key]), 3)
                .replace('e+', 'E')
                .replace('e-', 'E-')}`;
            } else {
              aggregatedItem[key] = aggregatedItem[key][0];
            }
          }
        }
          */

        return (
          <Row>
            <DetailsRow
              className={className}
              styles={{ cell: cellStyles }}
              {...rowProps}
              item={aggregatedItem}
              onRenderCheck={() => {
                return <div style={{ height: '36px', width: '40px' }}></div>;
              }}
            />
          </Row>
        );
      }

      return (
        <Row>
          <DetailsRow className={className} styles={{ cell: cellStyles, root: { borderBottom: '1px solid #D4D2D0' } }} {...rowProps} />
        </Row>
      );
    },
    [activeId, similarId, appContent]
  );

  const DEFAULT_ICON_COLOR = '#005776';

  const [checkboxVisibility, setCheckboxVisibility] = useState('always');
  const newTableProps = useRef(false);

  const pdfDownloadWaitingRef = useRef();
  pdfDownloadWaitingRef.current = pdfDownloadWaiting;

  const processIcon = (name, action, item, data = {}) => {
    if (action.click === 'datasheet') {
      return (
        <RoundIconButton
          key="round-icon-button-details"
          data-testid={`details-button`}
          iconProps={{
            iconName: name,
          }}
          onClick={() => {
            onEntityInvoke(item);
            //onActiveEntityChanged(item);
            //handleSelectedEntitiesChange([item]);
          }}
          title="Details"
        />
      );
    } else if (action.click === 'similar' && (!data.permission || hasPermission(perms, data.permission))) {
      let menuProps = null;
      if (action.options && action.options.length > 1) {
        let options = [];
        for (let option of action.options) {
          options.push({
            key: option.key,
            text: option.name,
            onClick: () => {
              findSimilarMaterials(item, option);
            },
          });
        }
        menuProps = {};
        menuProps.items = options;
      }

      return (
        <RoundIconButton
          data-testid={`similar-button`}
          key="round-icon-button-similar"
          iconProps={{
            iconName: name,
          }}
          title="Similar Materials"
          onClick={() => {
            if (isEmpty(menuProps)) {
              findSimilarMaterials(item);
            }
          }}
          onMouseLeave={() => {
            setTimeout(() => {
              const similarMaterialsMenu = document.querySelector('.ms-ContextualMenu');
              if (similarMaterialsMenu) {
                setTimeout(() => {
                  if (!similarMaterialsMenu.isMouseInside) {
                    similarMaterialsMenu.style.display = 'none';
                  }
                }, 500);
              }
            }, 500);
          }}
          onMenuClick={() => {
            setTimeout(() => {
              const similarMaterialsMenu = document.querySelector('.ms-ContextualMenu');
              if (similarMaterialsMenu) {
                similarMaterialsMenu.addEventListener('mouseover', () => {
                  similarMaterialsMenu.isMouseInside = true;
                });

                similarMaterialsMenu.addEventListener('mouseout', () => {
                  similarMaterialsMenu.isMouseInside = false;

                  setTimeout(() => {
                    if (!similarMaterialsMenu.isMouseInside) {
                      similarMaterialsMenu.style.display = 'none';
                    }
                  }, 100);
                });
              }
            }, 0);
          }}
          menuProps={menuProps}
        />
      );
    } else if (action.click === 'contextMenu') {
      return (
        <RoundIconButton
          key="round-icon-button-more-options"
          data-testid={`more-options-button`}
          iconProps={{
            iconName: name,
          }}
          onClick={(event) => {
            handleContextMenu(item, event);
          }}
          title="More options"
        />
      );
    } else if (action.click === 'link') {
      return (
        <RoundIconButton
          key="round-icon-button-link"
          data-testid={`link-button`}
          iconProps={{
            iconName: name,
          }}
          onClick={() => {
            if (action.bbpage) {
              window.bbPageModal(null, 'page=' + action.bbpage);
            } else if (action.url) {
              window.open(action.url, action.target || '_blank');
            }
          }}
          title="Link"
        />
      );
    } else if (action.click === 'pdf' && (!data.permission || hasPermission(perms, data.permission))) {
      if (!pdfDownloadWaitingRef.current.has(item.id)) {
        return (
          <RoundIconButton
            key="round-icon-button-pdf"
            data-testid={`pdf-button`}
            iconProps={{
              iconName: name,
            }}
            onClick={() => {
              downloadPDF(item, processSubstitution(action.materialname, item));
            }}
            title="Download PDF"
          />
        );
      } else {
        return <Spinner key="details-spinner" data-testid={`loading-spinner`} size={SpinnerSize.small} />;
      }
    } else if (action.click === 'share' && (action.clipboard || action.email)) {
      let items = [];

      if (action.clipboard) {
        items.push({
          key: 'clipboard',
          text: TextMapping.getUIText(TextMapping.UI_TEXT_COPY_TO_CLIPBOARD, texts),
          iconProps: { iconName: 'Copy' },
          onClick: () => {
            let shareURL = producturl(
              processSubstitution(action.materialname, item),
              window.location.protocol + '//' + window.location.host + window.location.pathname,
              track.get(item.id)
            );

            navigator.clipboard.writeText(shareURL);
            notifyShared();
          },
        });
      }
      if (action.email) {
        items.push({
          key: 'email',
          text: TextMapping.getUIText(TextMapping.UI_TEXT_SEND_VIA_EMAIL, texts),
          iconProps: { iconName: 'Mail' },
          onClick: () => {
            let shareURL = producturl(
              processSubstitution(action.materialname, item),
              window.location.protocol + '//' + window.location.host + window.location.pathname,
              track.get(item.id)
            );
            let mailText = TextMapping.getUIText(TextMapping.UI_TEXT_SHARE_TEXT, texts, {
              shareURL: encodeURI(shareURL),
              materialname: encodeURIComponent(processSubstitution(action.materialname, item)),
            });
            window.location.href = `mailto:?${mailText}`;
          },
        });
      }

      return (
        <RoundIconButton
          key="round-icon-button-share"
          data-testid={`share-button`}
          style={{
            backgroundColor: '#FFFFFF',
            borderRadius: '50%',
            boxShadow: '0 1px 4px 0 rgba(0,0,0,0.24)',
          }}
          iconProps={{
            iconName: name,
          }}
          menuProps={{
            items: items,
          }}
          onMouseLeave={() => {
            setTimeout(() => {
              const shareMaterialsMenu = document.querySelector('.ms-ContextualMenu');
              if (shareMaterialsMenu) {
                setTimeout(() => {
                  if (!shareMaterialsMenu.isMouseInside) {
                    shareMaterialsMenu.style.display = 'none';
                  }
                }, 500);
              }
            }, 500);
          }}
          onMenuClick={() => {
            setTimeout(() => {
              const shareMaterialsMenu = document.querySelector('.ms-ContextualMenu');
              if (shareMaterialsMenu) {
                shareMaterialsMenu.addEventListener('mouseover', () => {
                  shareMaterialsMenu.isMouseInside = true;
                });

                shareMaterialsMenu.addEventListener('mouseout', () => {
                  shareMaterialsMenu.isMouseInside = false;

                  setTimeout(() => {
                    if (!shareMaterialsMenu.isMouseInside) {
                      shareMaterialsMenu.style.display = 'none';
                    }
                  }, 100);
                });
              }
            }, 0);
          }}
          styles={{ label: { color: action.textColor ? action.textColor : DEFAULT_ICON_COLOR } }}
          title="Share"
        />
      );
    }
  };

  useEffect(() => {
    newTableProps.current = true;
  }, [preferences, track, activeId, updateFavoriteStatus, favoriteList, expandedGroups]);

  useEffect(() => {
    let tempItems = [];

    for (let buildingBlocks of buildingBlocksArray) {
      if (buildingBlocks && buildingBlocks.type === 'table') {
        //columns = buildingBlocks.data.columns;
        tempItems.push(...buildingBlocks.data.content);
        if (buildingBlocks.data.checkboxVisibility) {
          setCheckboxVisibility(buildingBlocks.data.checkboxVisibility);
        }

        if (!columns || newTableProps.current) {
          if (newTableProps.current) {
            newTableProps.current = false;
          }
          for (let column of buildingBlocks.data.columns) {
            column.onColumnHeaderClick = onColumnHeaderClick;
            column.key = column.fieldName;

            if (column.key !== 'expand') {
              column.onRender = (item, itemIndex, columnDefinition) => {
                let cellContent = [];
                let cellAction = [];

                if (columnDefinition.action && columnDefinition.action.hover && !item.isParent) {
                  processBuildingBlock({
                    appContent,
                    buildingBlock: columnDefinition.action.hover,
                    body: cellAction,
                    processIcon: processIcon,
                    data: item,
                    texts,
                  });
                }

                if (columnDefinition.template) {
                  processBuildingBlock({
                    appContent,
                    buildingBlock: columnDefinition.template,
                    body: cellContent,
                    processIcon: processIcon,
                    data: item,
                    texts,
                  });
                } else if (columnDefinition.type === 'icon') {
                  const imgWidth = columnDefinition.width || columnDefinition.minWidth || '20px';
                  cellContent.push(
                    <img
                      key={'image-' + columnDefinition.fieldName}
                      width={imgWidth}
                      src={'/mmdc' + item[columnDefinition.fieldName]}
                      alt={item.name}
                    />
                  );
                } else if (columnDefinition.type === 'favorite' && !item.isParent) {
                  cellContent.push(
                    <IconButton
                      key="icon-button-favorite"
                      title="Favorite"
                      iconProps={{
                        iconName: favoriteList.includes(item.id) ? 'FavoriteStarFill' : 'FavoriteStar',
                        styles: columnDefinition.styles,
                      }}
                      onClick={() => updateFavoriteStatus(item.id)}
                    />
                  );
                } else {
                  let content = item[columnDefinition.fieldName];
                  if (Array.isArray(item[columnDefinition.fieldName])) {
                    content = item[columnDefinition.fieldName].join(' > ');
                  }
                  cellContent.push(<div key={'cell-content-' + columnDefinition.fieldName}>{content}</div>);
                }

                let cell = (
                  <Cell depth={item.treeDepth}>
                    <CellContent data-automationid="ContentList-Column-Name">{cellContent}</CellContent>
                    <CellAction isMobile={isMobile}>{cellAction}</CellAction>
                  </Cell>
                );

                return cell;
              };
            } else {
              column.onRender = (item) => {
                if (item.isParent) {
                  return (
                    <button
                      className="expandButton"
                      onClick={() => setExpandedGroups((prev) => ({ ...prev, [item.groupKey]: !prev[item.groupKey] }))}
                      style={{
                        padding: '5px 10px',
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'transparent',
                      }}
                    >
                      <Icon
                        iconName={expandedGroups[item.groupName] ? 'ChevronDown' : 'ChevronRight'}
                        style={{ fontSize: '24px', lineHeight: '0px' }} // Adjust the size and spacing as needed
                      />
                      <span style={{ marginRight: '10px' }}>({item.groupCount})</span>
                    </button>
                  );
                } else {
                  return null;
                }
              };
            }
          }

          setColumns(buildingBlocks.data.columns.filter((column) => column.name));
        }
      } else if (buildingBlocks && buildingBlocks.type === 'style') {
        processBuildingBlock({ appContent, buildingBlock: buildingBlocks, addStyle, texts });
      }
    }

    setItems(tempItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingBlocksArray, newTableProps, pdfDownloadWaiting, activeId, updateFavoriteStatus, favoriteList, expandedGroups]);

  if (columns) {
    return (
      <div>
        {messageBar}
        <EntityList
          style={{ width: '50%' }}
          data-testid="material-entity-list"
          checkboxVisibility={checkboxVisibility}
          selectAllVisibility={0}
          enableShimmer={enableShimmer}
          loading={loading}
          actions={actions}
          columns={columns}
          entities={appContent?.config?.groupmaterials ? flattenedItems : items}
          onRenderDetailsHeader={onRenderDetailsHeader}
          onSelectedEntitiesChange={handleSelectedEntitiesChange}
          onEntityInvoke={onEntityInvoke}
          onRenderRow={_onRenderRow}
          constrainMode={ConstrainMode.horizontalConstrained}
          //onActiveEntityChanged={onActiveEntityChanged}
          selectedEntities={selectedEntities}
          onColumnHeaderClick={onColumnHeaderClick}
          sortEntities={({ entities }) => {
            return entities;
          }}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          activeId={activeId}
        />
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(BuildingBlockList);
