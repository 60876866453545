/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { CommandBar } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { common } from '../../../propTypes';
import { ButtonStyle } from './styled';
import { styled } from '@fluentui/utilities';

const DEFAULT_ARRAY = [];

function PanelControlsBase(props) {
  const { activePanelId, items = DEFAULT_ARRAY, onClick, theme } = props;

  const handleClick = useCallback(
    (e, item) => {
      const { key } = item;
      onClick(key);
    },
    [onClick]
  );

  const buttonStyle = ButtonStyle(theme);

  let farItems = [
    {
      'data-testid': 'Feedback',
      key: 'FEEDBACK',
      text: 'Feedback',
      onClick: handleClick,
      buttonStyles: buttonStyle,
    },
    ...items.map((control) => ({
      ...control,
      buttonStyles: buttonStyle,
      'data-testid': control.key,
    })),
    {
      key: 'ACCOUNT',
      checked: activePanelId === 'ACCOUNT',
      'data-testid': 'ACCOUNT',
      iconProps: {
        iconName: 'Contact',
        styles: {
          root: { paddingRight: '10px' },
        },
      },
      onClick: handleClick,
      buttonStyles: buttonStyle,
    },
  ];

  return (
    <div data-testid="panel-controls" className="panelControls">
      <CommandBar
        farItems={farItems}
        styles={{
          root: {
            background: 'none',
            color: '#FFF',
            padding: '0 10px',
            height: 32,
          },
        }}
      />
    </div>
  );
}

PanelControlsBase.propTypes = {
  activePanelId: PropTypes.string,
  items: common.ControlItemsProps,
  onClick: PropTypes.func,
};

PanelControlsBase.defaultProps = {
  activePanelId: null,
  onClick: () => {},
};

const PanelControls = styled(PanelControlsBase, () => {}, undefined, {
  scope: 'NotificationBellIcon',
});

export default PanelControls;
