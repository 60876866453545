/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import FilterGroup from './FilterGroup';
import GroupExpander from './GroupExpander';
import propTypes from './propTypes';
import { Divider, Header } from './styled';
import updateFilter from './utils/updateFilter';
import updateFilterCollapsed from './utils/updateFilterCollapsed';
import updateFilters from './utils/updateFilters';
import updateGroup from './utils/updateGroup';
import updateGroups from './utils/updateGroups';

function Filters(props) {
  const { groups, header, onChange, onResetFilter, selectedItems, toggleGroup, appContent } = props;

  const handleChangeGroup = useCallback(
    ({ group, filter, newValue }) => {
      let filters = group.filters;

      const newFilter = updateFilter({ filter, newValue });
      const newFilters = updateFilters({ filters, newFilter });
      const newGroup = updateGroup({ group, newFilters, newValue });
      const newGroups = updateGroups({ groups, newGroup });

      onChange({
        filter: newFilter,
        group: newGroup,
        groups: newGroups,
      });
    },
    [groups, onChange]
  );

  const handleToggleGroup = useCallback(
    (group, filter) => {
      let filters = group.filters;
      const newFilter = updateFilterCollapsed({ filter });
      const newFilters = updateFilters({ filters, newFilter });
      const newGroup = updateGroup({ group, newFilters });
      const newGroups = updateGroups({ groups, newGroup });

      toggleGroup(newGroups);
    },
    [groups, toggleGroup]
  );

  const handleResetFilter = useCallback(
    (group) => {
      let filters = group.filters;
      const newFilters = filters.map((filter) => ({ ...filter, value: false }));
      const newGroup = updateGroup({ group, newFilters, newValue: false });
      onResetFilter({ group: newGroup });
    },
    [onResetFilter]
  );

  return (
    <div className="filtersText filtersBackground">
      {!header.isHidden && (
        <div className="bodyText filtersText filterHeaderText" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Header>{header.name}</Header>
        </div>
      )}

      {groups.map((group) => {
        const { id, name } = group;

        return (
          <GroupExpander
            data-testid={`GroupExpander-${name}`}
            isActive={group.isActive}
            isExpanded={group.isExpanded}
            key={id}
            onResetFilter={handleResetFilter}
            text={name}
            group={group}
            appContent={appContent}
          >
            <FilterGroup group={group} selectedItems={selectedItems} onChange={handleChangeGroup} toggleGroup={handleToggleGroup} />
          </GroupExpander>
        );
      })}
      <Divider />
    </div>
  );
}

Filters.propTypes = {
  groups: PropTypes.arrayOf(propTypes.group),
  selectedItems: PropTypes.arrayOf(PropTypes.string.isRequired),
  header: PropTypes.shape({
    isHidden: PropTypes.bool,
    name: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onResetFilter: PropTypes.func,
  toggleGroup: PropTypes.func,
};

Filters.defaultProps = {
  groups: [],
  header: {
    name: 'Filters',
  },
  selectedItems: [],
  onChange: () => {},
  onResetFilter: () => {},
};

export default React.memo(Filters);
